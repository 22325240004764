<template>
  <div class="container container-hidder">
    <div id="carouselExampleIndicators2"
         class="carousel slide"
         data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators2"
            data-slide-to="0"
            class="active indicator"></li>
        <li data-target="#carouselExampleIndicators2"
            data-slide-to="1"
            class=" indicator"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="d-block w-100 item-container">
            <TablaBeneficios />
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-block w-100 item-container">
            <TablaOpciones />

          </div>
        </div>
      </div>
      <a class="carousel-control-prev"
         href="#carouselExampleIndicators2"
         role="button"
         data-slide="prev">
        <span class="carousel-control-prev-icon"
              aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next"
         href="#carouselExampleIndicators2"
         role="button"
         data-slide="next">
        <span class="carousel-control-next-icon"
              aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <div class="container-background">
    <div class="container desktop-carousel">
      <TablaBeneficios />
      <TablaOpciones />
    </div>
    <div class="button-container">
      <div class="btn background-orange text-center text-white regular-text  btn-solicitar ">
        <router-link 
                     to="/#banner-form"
                     class="text-white">
          Hacer solicitud
        </router-link>

      </div>
      <router-link to="como-funciona"
                   class="text-blue bold-text faqs mb-2">
        ¿Tienes dudas?
      </router-link>
    </div>
  </div>



</template>

<script>
import TablaBeneficios from './TablaBeneficios.vue';
import TablaOpciones from './TablaOpciones.vue';


export default {
  name: "BeneficiosIbancarCarousel",
  components: {
    TablaBeneficios,
    TablaOpciones
  },
  methods: {
    goTo(route, hash) {
      this.$router.push({ name: route, hash: hash });
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.faqs {
  color: #004680;
  text-align: center;
  font-family: 'Montserrat-Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;

}

.btn-solicitar {
  border-radius: 30px;
  padding: 10px 65px;
  ;
  min-width: 250px;
  font-family: 'Montserrat-Bold';
}

.background-orange {
  background-color: #FFA500;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}


.text-white {
  font-size: 1.5rem;
}

s .image {
  height: 320px;
}

.desktop-carousel {
  display: none;
}

.container-hidder {
  padding: 2rem 2.5rem;
}



.faqs-text {
  color: #004680;
  text-align: center;
  font-family: 'Montserrat-Bold';
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
}

@include media-breakpoint-up(lg) {

  .desktop-carousel {
    display: flex;
    gap: 4rem;
    max-width: 830px;
  }

  .container-hidder {
    display: none;
  }

}

.item-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.carousel-inner {
  height: fit-content;
}

.indicator {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #D8D8D8;
}

.carousel-indicators {
  bottom: -4rem;

  .active {
    background-color: #004680;
  }
}

@include media-breakpoint-only(md) {
  .container-hidder {
    padding: 2rem 9rem;
  }
}

@include media-breakpoint-down(md) {


  .faqs {
    text-decoration: none;
  }
}
</style>