<template>
    <div class="banner-container mx-auto"
         :class="container">
        <div class="row ml-4 ml-md-0">
            <div class="col-md-7 col-7">
                <h1 class="bold-text text-blue titulo mt-5 pt-2 pt-md-0 mt-md-0"
                    v-if="titulo || valor">
                    <!-- titulo y o monto -->
                    <div v-html=" titulo "></div>
                    <span class="text-orange"
                          v-if="valor">
                        ${{ formatNumber(valor) }}
                    </span>
                </h1>
                <div>
                    <!-- Subtitulo -->
                    <span class="mt-4 text-blue subtitulo d-none d-lg-block bold-text"
                       v-if="subtitulo">
                        {{ subtitulo }}
                </span>
                    <!-- Listado -->
                    <div class="mt-4 mb-3 mt-md-3 text-blue subtitulo bold-text"
                         v-if="listado"
                         :class="{ 'pt-lg-5 pt-3': !subtitulo }">
                        <p class="mb-1 mb-md-0 bold-text"
                           :key="key"
                           v-for="(item, key) in listado">
                            <span class="mr-2">
                                <img class="stroke"
                                     src="@/assets/img/icons/stroke-blue.svg" />
                            </span>{{ item }}
                            <br v-if="key < listado.length" />
                        </p>
                    </div>
                </div>
            </div>
            <!-- Imagen desktop -->
            <div class="col-5 d-none image-banner-container d-lg-flex">
                <img src="@/assets/img/home/new-portada.svg"
                     class="position-relative banner-image mt-auto"
                     alt="hombreymujer">
            </div>
            <!-- Imagen responsive -->
            <div class="col-4 d-lg-none position-relative">
                <img src="@/assets/img/home/new-portada.svg"
                     class="image-responsive"
                     alt="Chico buscando" />
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'SectionBanner',
    props: {
        titulo: String,
        valor: String,
        subtitulo: String,
        listado: Array,
        container: String,
    },
    methods: {
        formatNumber(number) {
            // Formateo a números
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.banner-container {
    width: 85%;
    max-height: 29rem;
}

.image-banner-container {
    height: fit-content;
}

.titulo {
    font-size: 48px;
    // line-height: 60px;
    text-align: left;
}

.subtitulo {
    font-weight: 500;
    font-family: 'Montserrat';
    font-size: 28px;
    // line-height: 48px;

    p {
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 600;
        // line-height: 18px;
    }
}

.stroke {
    width: 25px;
}

.text-blue {
    color: $blue;
}

.medium-text {
    font-family: 'Montserrat-Medium';
}

.bold-text {
    font-family: 'Montserrat-Bold';
    font-weight: bold;
}

.image-responsive {
    width: auto;
    margin-left: -70px;
    margin-bottom: -30px;
    z-index: -1;
}

.banner-image {
    z-index: 1;
    bottom: -19px;
    height: 35rem;
    width: 60rem;
}

.text-orange {
    color: $orange;
}

@include media-breakpoint-up(xl) {
    .banner-image {
        left: -70%;
    }

}

@include media-breakpoint-up(md) {
    .subtitulo {
        p {
            font-family: Montserrat;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 600;
            // line-height: normal;
            margin-bottom: 1rem !important;
        }
    }
}

@include media-breakpoint-between(md, lg) {

    .banner-image {
        left: -104%;
    }
}

@media screen and (min-width: 1600px) {
    .banner-image {
        left: -13rem;
        z-index: 1;
    bottom: -21px;
    height: 35rem;
    width: 60rem;
    }
}

@media screen and (min-width: 768px) {
    .image-responsive {
        width: 660px;
        padding-left: 60px;
        margin-left: -15rem !important;
    }
}

@include media-breakpoint-down(md) {
    .stroke {
        width: 13px;
    }

    .banner-container {
        width: 100%;
    }

    .titulo {
        font-size: 20px;
        // line-height: 1.1;
        text-align: left;
    }

    .subtitulo {
        font-size: 13px;
        // line-height: 1.1;
    }

    .image-responsive {
        position: absolute;
        top: 15%
    }
}

@include media-breakpoint-between(md, lg) {
    .banner-container {
        width: 80%;
    }

    .titulo {
        font-size: 35px;
        // line-height: 1.1;
        text-align: left;
    }

    .stroke {
        width: 15px;
    }

    .subtitulo {
        font-size: 18px;
        // line-height: 1.1;
    }

    .image-responsive {
        padding-left: 60px;
        margin-left: -22rem;
        z-index: 1;
    }
}

@include media-breakpoint-down(sm) {

    .image-responsive {
        height: 200px;
        margin-top: 58px;
        left: -10%;
        bottom: -7%;
        position: absolute;
        z-index: 1;
    }
}
</style>