<template>
    <EnlazadoInterno v-if="this.$route.fullPath == '/'" />
    <section class="companies"
             style="background: rgba(255, 200, 100, 0.07);">
        <div id="desktop">
            <div class=" pb-3 mb-5 text-center">
                <p class="subtitle text-center">Lo que dicen de nosotros en prensa</p>
            </div>
            <div id="prensa"
                 class="d-flex flex-row justify-content-center text-wrap m-4">
                <a href="https://nation.com.mx/economia/ganar-dinero-ibancar/"
                   target="_blank"
                   rel="nofollow noopener"><img width="198"
                         height="47"
                         class="img-fluid mr-5"
                         src="../../assets/img/logo-nacion-mexicana.png"
                         alt="nacion-mexicana">
                </a>
                <a href="https://diario21.com.mx/aprovecha-el-valor-de-tu-auto-con-ibancar-prestamos-personales-con-garantia-vehicular/"
                   rel="nofollow noopener"
                   target="_blank"><img width="198"
                         height="47"
                         class="img-fluid mr-5"
                         src="../../assets/img/logo-diario-21.png"
                         alt="diario 21">
                </a>

                <a href="https://finance.yahoo.com/news/spanish-company-ibancar-raises-1-080000459.html?guccounter=1"
                   rel="nofollow noopener"
                   target="_blank"><img width="198"
                         height="47"
                         id="yahoo"
                         class="img-fluid mr-5"
                         src="../../assets/img/logo-yahoo.webp"
                         alt="yahoo!">
                </a>

                <a href="https://www.europapress.es/comunicados/internacional-00907/noticia-comunicado-empresa-espanola-ibancar-completa-primera-ronda-financiacion-capital-externo-levantando-165-millones-20201124090107.html"
                   rel="nofollow noopener"
                   target="_blank"><img width="198"
                         height="47"
                         class="img-fluid mr-5"
                         src="../../assets/img/logo-europapress.webp"
                         alt="europapress">
                </a>
            </div>
        </div>
        <div id="carousel">
            <div id="mobile"
                 class="carousel slide text-center mb-3"
                 data-touch="true"
                 data-ride="carousel">
                <p class="subtitle text-center mb-2">Lo que dicen de nosotros en prensa</p>
                <div class="carousel-inner" style="height: 94px">
                    <div class="carousel-item active">
                        <div class="card p-2 center"
                             style="background-color: transparent;">
                            <a href="https://diario21.com.mx/aprovecha-el-valor-de-tu-auto-con-ibancar-prestamos-personales-con-garantia-vehicular/"
                               rel="nofollow noopener"
                               target="_blank">
                                <img style="width:13rem;"
                                     src="../../assets/img/logo-diario-21.png"
                                     class="card-img-top"
                                     alt="ibancar_diario21">
                            </a>

                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card p-2 center"
                             style="background-color: transparent;">
                            <a href="https://finance.yahoo.com/news/spanish-company-ibancar-raises-1-080000459.html?guccounter=1"
                               rel="nofollow noopener"
                               target="_blank">
                                <img style="width:13rem;"
                                     src="../../assets/img/logo-yahoo.webp"
                                     class="card-img-top"
                                     alt="ibancar_yahoo">
                            </a>

                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card p-2 center"
                             style="background-color: transparent;">
                            <a href="https://www.europapress.es/comunicados/internacional-00907/noticia-comunicado-empresa-espanola-ibancar-completa-primera-ronda-financiacion-capital-externo-levantando-165-millones-20201124090107.html"
                               rel="nofollow noopener"
                               target="_blank">
                                <img style="width:13rem;"
                                     src="../../assets/img/logo-europapress.webp"
                                     class="card-img-top"
                                     alt="ibancar_europapress">
                            </a>

                        </div>
                    </div>

                    <div class="carousel-item ">
                        <div class="card p-2 center"
                             style="background-color: transparent;">
                            <a href="https://nation.com.mx/economia/ganar-dinero-ibancar/"
                               target="_blank"
                               rel="nofollow noopener">
                                <img style="width:13rem;"
                                     src="../../assets/img/logo-nacion-mexicana.png"
                                     class="card-img-top"
                                     alt="ibancar_nacionmexicana">
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <section class="section9-container pb-5">
        <section class="location container">
            <div class="location-info">
                <div style="text-align: center;">
                    <p class="subtitle">{{ windowWith >= 768 ? 'Contáctanos' : 'Si tienes alguna duda estaremos encantados de recibirte' }}</p>
                </div>
                <div class="location-list">
                    <div class="location-list-container">
                        <div class="location-info-list">
                            <img src="@/assets/img/home/direction.png"
                                 alt=""
                                 class="location-info-image">
                            <span class="location-info-loc">Av. Paseo de la Reforma 180, piso 11, interior 1101,
                                Juárez,
                                Cuauhtémoc, 06600 Ciudad de México, CDMX.</span>
                        </div>
                        <div class="location-info-list"
                             v-if="windowWith >= 768">
                            <img src="@/assets/img/home/email-icono.png"
                                 alt=""
                                 class="location-info-image">
                            <span class="location-info-location">hola@ibancar.mx</span>
                        </div>
                        <div class="location-info-list"
                             v-if="windowWith >= 768">
                            <img src="@/assets/img/home/phone-icon.png"
                                 alt=""
                                 class="location-info-image">
                            <span class="location-info-location"><a
                                   href="https://api.whatsapp.com/send?l=es&phone=5215612673878&text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20sobre%20el%20pr%C3%A9stamo%20con%20garant%C3%ADa%20de%20auto.%20Gracias."
                                   target="_blank">
                                    +52 55 5566 1583 <br> ¡Escríbenos por WhatsApp!
                                </a> </span>
                        </div>


                    </div>
                    <div class="location-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.6505635115352!2d-99.16131193038439!3d19.429584063596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff330c50e217%3A0x1c2970ef16645208!2sAv.%20P.%C2%BA%20de%20la%20Reforma%20180-piso%2011%2C%20interior%201101%2C%20Ju%C3%A1rez%2C%20Cuauht%C3%A9moc%2C%2006600%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1720201065525!5m2!1ses-419!2smx"
                                frameborder="0"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { useRoute } from 'vue-router';
import EnlazadoInterno from "@/components/EnlazadoInterno/EnlazadoInterno.vue";

export default {
    name: 'Section9',
    components: { EnlazadoInterno },

    setup() {
        const route = useRoute();

        return {
            route
        }
    },
    data() {
        return {
            windowWith: window.innerWidth
        }
    },
    methods: {
        getWindowWidth() {
            this.windowWith = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.getWindowWidth);
    },
    watch: {
        windowWith(value) {
            return value;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.companies {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #004680;
    font-weight: 800;
    padding: 1rem;

    .companies-title {
        span {
            color: #004680;

        }
    }
}

@media screen and(max-width: 768px) {
    .companies {
        background-color: rgba(255, 200, 100, 0.07);
    }

}

.subtitle {
    margin: 0;
    font-family: 'Montserrat-Bold';
    font-size: 31px;
    color: #004680;
}

.section9-container {
    background: rgba(179, 220, 255, 0.14);
}

.location-map {
    width: 100%;

    iframe {
        border-radius: 1rem;
        border: 1px solid #8080803b;
        box-shadow: 2px 2px 2px 2px #8080803b;
        width: 100%;
    }
}

.location-list {
    display: flex;
    align-items: center;

    justify-content: center;
    gap: 1rem;
    flex-direction: column;
}

.location-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    div {
        width: 100%;

    }
}


.location-info-loc {
    margin-left: 0.5rem !important;
}

.location {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;


    .location-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;



    }

    .location-info-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        span {
            text-align: justify;
            font-size: 0.9rem;
            font-weight: 800;
        }

        img {
            height: 2rem;
        }
    }

    span {
        color: #004680;
    }
}

.location-info-location {
    a {
        text-decoration: none;
        color: #004680;
        cursor: pointer;
    }
}

@include media-breakpoint-up(lg) {

    .location-list {
        flex-direction: row;
        gap: 5rem;
    }

    .location-map {
        height: 20rem;

        iframe {
            height: 100%;
        }
    }

}

/* purgecss ignore */
#opiniones {
    .card {
        z-index: -1;
        width: 20rem;
        height: 16rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

#desktop {
    .card {
        z-index: -1;
        width: 19rem;
        height: 16rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

#prensa {
    .card {
        z-index: -1;
        box-shadow: none;
        border: none;
    }
}

#socios {
    .card {
        z-index: -1;
        box-shadow: none;
        border: none;
    }
}

.h4-text-verde {
    font-weight: bold;
    color: #00C4A7;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.h3-text-verde {
    font-weight: bold;
    color: #00C4A7;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.h4-text-azul {
    font-weight: bold;
    color: #004AAD;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.h3-text-azul {
    font-weight: bold;
    color: #004AAD;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.h4-text-salmon {
    font-weight: bold;
    color: #FFB97F;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.h3-text-salmon {
    font-weight: bold;
    color: #FFB97F;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.background-gray {
    background-color: #f8f8f8;
}

// Resposive para pantallas a partir de tamaño medio a pequeño
@include media-breakpoint-up(md) {
    .subtitle {
        font-size: 31px;
    }
}

@include media-breakpoint-between(xs, sm) {

    #opiniones {
        .card {
            width: 17rem;
            height: 15rem;
            font-size: 1.8vh;
            font-size: 0.85rem;
        }
    }

    .padding {
        padding: 1.5rem;
    }

    #desktop {
        display: none !important;
    }

    #carousel {
        width: 100%;

        #mobile {
            .card {
                border: none;
                margin-left: 12.5%;
                margin-right: 12.5%;
                height: auto;
                width: 75%;
            }
        }
    }

    .h4-text-verde {
        font-size: 18.5px;
    }

    .h3-text-verde {
        font-size: 50px;
    }

    .h4-text-azul {
        font-size: 18.5px;
    }

    .h3-text-azul {
        font-size: 50px;
    }

    .h4-text-salmon {
        font-size: 18.5px;
    }

    .h3-text-salmon {
        font-size: 50px;
    }

    .card-img-top {
        border: none;
    }

}

#carousel {
    display: none;
}

@include media-breakpoint-down(md) {

    #desktop {
        display: none !important;
    }

    .padding {
        padding: 0;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    #carousel {
        display: block;

        #mobile {
            .card {
                border: none;
                margin-left: 25%;
                margin-right: 25%;
                height: auto;
            }
        }
    }

    .h4-text-verde {
        font-size: 1.3rem;
        padding-bottom: 25px;
    }

    .h3-text-verde {
        font-size: 60px;
    }

    .h4-text-azul {
        font-size: 1.3rem;
        padding-bottom: 25px;
    }

    .h3-text-azul {
        font-size: 60px;
    }

    .h4-text-salmon {
        font-size: 1.3rem;
    }

    .h3-text-salmon {
        font-size: 60px;
    }
}

@include media-breakpoint-between(xl, xxl) {
    #mobile {
        display: none !important;
    }

    .padding {
        padding: 2.5rem;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    .h4-text-verde {
        font-size: 22.5px;
    }

    .h3-text-verde {
        font-size: 50px;
    }

    .h4-text-azul {
        font-size: 1.2rem;
    }

    .h3-text-azul {
        font-size: 50px;
    }

    .h4-text-salmon {
        font-size: 1rem;
    }

    .h3-text-salmon {
        font-size: 50px;
    }
}

@include media-breakpoint-down(sm) {
    .subtitle {
        font-size: 17px;
        margin: 0rem 1px;
        margin-bottom: 2rem !important;

    }
}
</style>