<template>
    <!-- Select reutilizable que te permite escoger entre si tienes la factura o no -->
    <select class="py-md-3 py-2 form-control h-md-100 text-blue bold-text" v-model="factura" :class="printClass()">
        <option disabled value="0">¿Tienes la factura de tu auto?</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
    </select>
    <!-- Mensaje de error -->
    <p v-show="errorFlag" class="d-md-none text-danger mt-1 mb-0 pl-3 bold-text">
        Este campo es requerido
    </p>
</template>

<script>
    import store from "@/store";
    
    export default {
        name: 'SelectFactura',
        data () {
            return {
                factura: '0'
            }
        },
        props: {
            errorFlag: Boolean,
            customClass: String
        },
        store,
        methods: {
            printClass: function() {
                // En caso de contar con una customClass como input, entonces se concatena con los valores de error o tiene valor.
                let customClass = '';
                if(this.errorFlag){
                    customClass += 'is-invalid '
                }
                if(this.factura !== 0 && this.factura){
                    customClass += 'has-value '
                }
                if(this.customClass){
                    customClass += this.customClass
                }
                return customClass
            }
        },
        watch: {
            factura: function(value){
                // Se actualiza el valor de setTitular
                store.commit('setTitular', value === 0 ? '0' : value)
            }
        },
        mounted: function(){
            if(this.factura === 0){
                store.commit('setTitular', 0)
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    .form-control{
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button{
            -webkit-appearance: none;
        }
        height: auto;
        border-radius: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url("../../../assets/img/Vector.svg") !important;
        background-position: 95% 50%;
        background-repeat: no-repeat;
        background-clip: content-box;
        background-size: 1.1rem;
        padding: 0px 0px 0px 16px;
        background-color: $white;
        border-width: 2px;

        &:disabled {
            background-color: transparentize($gray-2, .8);
            background-size: auto;
            background-clip: padding-box;
            cursor: not-allowed;
        }

        &.is-invalid{
            background-size: auto;
            background-clip: padding-box;
        }

        &.has-value {
            color: $blue;
        }
        
        min-height: 48px;
    }

    .bold-text{
        font-family: 'Montserrat-Bold';
        font-weight: bold;
    }

    .bg-white{
        background-color: white;
        background-size: auto;
        background-clip: padding-box;
    }

    .text-blue{
        color: $blue;
    }
</style>